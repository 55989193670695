export const MODULE_ID_PUSH_MESSAGES = "VirtoCommerce.PushMessages";
export const MODULE_ID_XRECOMMEND = "VirtoCommerce.XRecommend";
export const XRECOMMEND_ENABLED_KEY = "XRecommend.RecommendationsEnabled";

export const APP_INSIGHTS_MODULE_ID = "VirtoCommerce.ApplicationInsights";
export const APP_INSIGHTS_ENABLE_STATE = "ApplicationInsights.EnableTracking";
export const APP_INSIGHTS_INSTRUMENTATION_KEY = "ApplicationInsights.InstrumentationKey";

export const MODULE_XAPI_KEYS = {
  MODULE_ID: "VirtoCommerce.Xapi",
  SUPPORT_PHONE_NUMBER: "Frontend.SupportPhoneNumber",
  PAGE_TITLE_WITH_STORE_NAME: "Frontend.PageTitleWithStoreName",
  PAGE_TITLE_STORE_NAME_ALIGN: "Frontend.PageTitleStoreNameAlign",
  PAGE_TITLE_DIVIDER: "Frontend.PageTitleDivider",
  CATALOG_MENU_LINK_LIST_NAME: "Frontend.CatalogMenuLinkListName",
  CATALOG_EMPTY_CATEGORIES_ENABLED: "Frontend.CatalogEmptyCategoriesEnabled",
} as const;
